import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

import logoTagline from "../images/logos/Autos2050_Logo_Color_KO_Tagline.png"
import logoNoTagline from "../images/logos/Autos2050_Logo_KO.png"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BeFeaturedBanner from "../components/beFeaturedBanner"
import Modal from "../components/modal"
import SponsorshipForm from "../components/sponsorshipForm"
import LeaderEventCard from "../components/leaderEventCard"

//Swiper Imports
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { format } from "date-fns"
import { AnimatePresence, motion } from "framer-motion"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

function IndexPage({ data }) {
  const [animateEvent, setAnimateEvent] = useState(true)
  const [loading, setLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const site = (data || {})?.site

  // NOTE — this is a terrible fix for an issue with Portable Text. See: https://github.com/sanity-io/gatsby-source-sanity/issues/67#issuecomment-762499150
  site.about.map(i => {
    i.markDefs = []
    return i
  })

  // site.events.map(i => {
  //   i.markDefs = []
  //   return i
  // })

  const swiperBreakpoints = {
    768: {
      slidesPerView: 2,
    },
    1280: {
      slidesPerView: 3,
    },
    1920: {
      slidesPerView: 4,
    },
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.4,
        type: "tween",
        ease: "circOut",
      },
    },
  }

  const item = {
    hidden: {
      x: -50,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        ease: "circOut",
      },
    },
  }

  const eventContainer = {
    hidden: {
      opacity: 0,
    },
    eventShow: {
      opacity: 1,
      transition: {
        delay: 0.4,
        when: "beforeChildren",
        type: "tween",
        ease: "circOut",
      },
    },
  }

  const saveTheDate = {
    hidden: {
      x: "-100vw",
      opacity: 0,
    },
    eventShow: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 0.6,
        type: "tween",
        ease: "circOut",
      },
    },
  }

  const saveTheDateText = {
    hidden: {
      x: "-100vw",
      opacity: 0,
    },
    eventShow: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 0.6,
        duration: 0.6,
        type: "tween",
        ease: "circOut",
      },
    },
  }

  const event = {
    hidden: {
      x: "-100vw",
    },
    eventShow: {
      x: 0,
      transition: {
        delay: 1.0,
        duration: 0.6,
        type: "tween",
        ease: "circOut",
      },
    },
  }

  const eventText1 = {
    hidden: {
      x: "-100vw",
    },
    eventShow: {
      x: 0,
      transition: {
        delay: 1.4,
        duration: 0.6,
        type: "tween",
        ease: "circOut",
      },
    },
  }

  const eventText2 = {
    hidden: {
      x: "-100vw",
    },
    eventShow: {
      x: 0,
      transition: {
        delay: 1.5,
        duration: 0.6,
        type: "tween",
        ease: "circOut",
      },
    },
  }

  const eventButton = {
    hidden: {
      opacity: 0,
    },
    eventShow: {
      opacity: 1,
      transition: {
        delay: 2.0,
        duration: 0.6,
        type: "tween",
        ease: "circOut",
      },
    },
  }

  useEffect(() => {
    let eventQuery = window.matchMedia("(max-width: 768px)")
    if (eventQuery.matches) {
      setAnimateEvent(false)
      setLoading(false)
    } else {
      setAnimateEvent(true)
      setLoading(false)
    }
  }, [])

  return (
    <Layout noPadding={true}>
      <Seo title="Home" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section
          className="homepage-background-position flex flex-col pt-8 w-full min-h-screen text-white bg-cover bg-no-repeat md:h-screen"
          style={{ backgroundImage: `url('${site.image.asset.url}')` }}
        >
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className="container flex flex-col flex-wrap justify-center mx-auto pr-4 w-5/6 h-screen md:h-full lg:px-0 lg:w-8/12"
          >
            <motion.picture variants={item} className="w-full max-w-3xl">
              <source
                srcSet={logoNoTagline}
                media="(max-width: 768px)"
                alt="Autos2050 logo"
              />
              <img src={logoTagline} alt="Autos2050 logo" />
            </motion.picture>
            <motion.h1
              variants={item}
              className="my-4 text-secondary-100 text-2xl italic font-semibold lg:w-full"
            >
              {site.tagline}
            </motion.h1>
            <motion.p variants={item} className="max-w-xl text-sm md:text-base">
              {site.text}
            </motion.p>
          </motion.div>
          {/* TODO: Make content for event banner controllable via cms */}
          {!loading && (
            <section className="w-full text-white">
              <motion.div
                variants={eventContainer}
                initial={animateEvent ? "hidden" : false}
                animate="eventShow"
                className="flex flex-col w-full md:flex-row md:justify-between"
              >
                <motion.div
                  variants={saveTheDate}
                  className="z-10 flex flex-col justify-center 2xl:px-32 px-8 py-8 w-full bg-secondary-300 md:w-1/3 lg:px-16 xl:w-1/4"
                >
                  <motion.div
                    variants={saveTheDateText}
                    className="flex items-center justify-center space-x-4 md:justify-end"
                  >
                    {/* SVG GOES HERE */}

                    <div className="relative text-center text-lg md:top-1">
                      <span className="relative top-1 block tracking-wider">
                        CHECK IT
                      </span>

                      <div className="relative bottom-1 text-3xl font-bold tracking-wide">
                        OUT!
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
                <motion.div
                  variants={event}
                  className="flex flex-col items-center justify-between 2xl:px-32 px-8 py-8 w-full bg-secondary-400 bg-event-callout-starburst bg-cover bg-right bg-no-repeat space-y-4 md:flex-row md:w-2/3 md:bg-contain md:space-x-4 md:space-y-0 lg:px-16 xl:w-3/4"
                >
                  <div className="flex flex-col justify-center space-y-2">
                    <motion.h2
                      variants={eventText1}
                      className="2xl:text-4xl text-xl italic font-bold md:text-2xl xl:text-3xl"
                    >
                      {/* {site.eventBanner?.name} */}
                      On demand content from the Auto Innovators 2021 Summit
                    </motion.h2>
                  </div>
                  <motion.div variants={eventButton}>
                    <a href={`/past-events/summit-2021`}>
                      <button className="min-w-150 px-4 py-2 hover:text-secondary-200 font-bold hover:bg-white border-2 border-secondary-100 rounded-full focus:outline-none uppercase transition">
                        {/* {site.eventBannerButton} */}
                        WATCH NOW
                      </button>
                    </a>
                  </motion.div>
                </motion.div>
              </motion.div>
            </section>
          )}
        </section>

        <section className="w-full text-white bg-dark-200 bg-cover bg-center bg-no-repeat">
          <div className="flex flex-col items-center justify-center lg:flex-row">
            <div className="relative mx-auto py-8 w-full overflow-x-hidden lg:px-8 lg:py-16 lg:w-10/12">
              <h2 className="mb-8 text-center text-xl font-bold lg:ml-12 lg:text-3xl">
                {new Date().getFullYear()} Featured Thought Leaders
              </h2>
              <div className="swiper-width mx-auto w-full">
                <Swiper
                  slidesPerView={1}
                  navigation
                  breakpoints={swiperBreakpoints}
                  preventClicksPropagation={false}
                >
                  {site &&
                    site.leaders.map((leader, i) => (
                      <SwiperSlide key={i}>
                        <LeaderEventCard
                          name={leader.name}
                          position={leader.position}
                          image={leader.image.asset.url}
                          bio={leader.bio}
                          companyImage={leader.company.logo.asset.url}
                          eventDate={leader?.eventDate}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center w-full lg:w-min">
              <div className="flex items-center justify-center w-full h-auto text-white bg-webinar-teaser bg-cover bg-center bg-no-repeat lg:w-80">
                <div className="flex flex-col justify-center px-8 py-24 w-full h-full space-y-4">
                  <p className="text-sm font-bold tracking-widest">FORUM</p>
                  <h2 className="text-2xl font-bold lg:text-3xl">
                    {site.webinar.event?.name ?? ""}
                  </h2>
                  <p className="font-bold">{site.webinar.name}</p>
                  <Link to={`/resources/webinars?id=${site.webinar._id}`}>
                    <button className="min-w-300 flex items-center px-4 py-2 text-center hover:text-primary font-bold hover:bg-white border-2 border-white rounded-full focus:outline-none transition">
                      <svg
                        width="16"
                        height="16"
                        className="mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.5547 5.16795C7.24784 4.96338 6.8533 4.94431 6.52814 5.11833C6.20298 5.29235 6 5.63121 6 6V10C6 10.3688 6.20298 10.7077 6.52814 10.8817C6.8533 11.0557 7.24784 11.0366 7.5547 10.8321L10.5547 8.8321C10.8329 8.6466 11 8.3344 11 8C11 7.66565 10.8329 7.35342 10.5547 7.16795L7.5547 5.16795Z"
                          className="fill-current"
                        />
                      </svg>
                      <span>WATCH NOW</span>
                    </button>
                  </Link>
                </div>
              </div>

              <div className="w-full h-auto text-white bg-podcast-teaser bg-cover bg-center bg-no-repeat lg:w-80">
                <div className="flex flex-col justify-center px-8 py-16 w-full h-full space-y-4">
                  <p className="text-sm font-bold tracking-widest">PODCAST</p>
                  <h2 className="text-2xl font-bold lg:text-3xl">
                    The Overtake Podcast
                  </h2>
                  <Link to="/resources?q=podcasts">
                    <button className="min-w-300 flex items-center px-4 py-2 text-center font-bold bg-primary hover:bg-opacity-75 rounded-full focus:outline-none transition">
                      <svg
                        width="18"
                        height="16"
                        className="mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.38268 1.07615C8.75636 1.23093 9 1.59557 9 2.00003V14C9 14.4045 8.75636 14.7691 8.38268 14.9239C8.00901 15.0787 7.57889 14.9931 7.29289 14.7071L3.58579 11H1C0.44772 11 0 10.5523 0 10V6.00003C0 5.44774 0.44772 5.00003 1 5.00003H3.58579L7.29289 1.29292C7.57889 1.00692 8.00901 0.92137 8.38268 1.07615Z"
                          fill="white"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.6569 0.928876C14.0475 0.538356 14.6806 0.538356 15.0712 0.928876C16.8797 2.73743 18.0001 5.2388 18.0001 7.99995C18.0001 10.7611 16.8797 13.2625 15.0712 15.071C14.6806 15.4615 14.0475 15.4615 13.6569 15.071C13.2664 14.6805 13.2664 14.0473 13.6569 13.6568C15.1058 12.208 16.0001 10.2094 16.0001 7.99995C16.0001 5.79053 15.1058 3.7919 13.6569 2.34309C13.2664 1.95257 13.2664 1.3194 13.6569 0.928876ZM10.8285 3.75731C11.219 3.36678 11.8522 3.36678 12.2427 3.75731C12.7686 4.28319 13.1977 4.90687 13.5004 5.59958C13.8221 6.33592 14.0001 7.14847 14.0001 7.99995C14.0001 9.6565 13.3274 11.1579 12.2427 12.2426C11.8522 12.6331 11.219 12.6331 10.8285 12.2426C10.438 11.8521 10.438 11.2189 10.8285 10.8284C11.5535 10.1034 12.0001 9.1048 12.0001 7.99995C12.0001 7.42922 11.8812 6.8889 11.6677 6.40032C11.4664 5.93958 11.1803 5.52327 10.8285 5.17152C10.438 4.781 10.438 4.14783 10.8285 3.75731Z"
                          fill="white"
                        />
                      </svg>
                      <span>LISTEN NOW</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-col items-center justify-center py-24 w-full bg-white-right-starburst bg-cover bg-center bg-no-repeat">
          <div className="mx-auto w-5/6 text-left space-y-4 lg:w-2/5">
            <h2 className="text-primary text-2xl font-bold lg:text-4xl xl:text-5xl">
              About Autos2050®
            </h2>
            <BlockContent
              blocks={site.about}
              className="children:mb-5 leading-6"
            />
          </div>
        </section>

        <section className="flex flex-wrap justify-center w-full">
          <div className="flex flex-col justify-center w-full text-white bg-dark-200 lg:p-16 lg:w-1/2">
            <div className="p-16 space-y-8">
              <h2 className="text-2xl font-bold lg:text-4xl">
                Upcoming Events
              </h2>
              {site.events
                .slice(0, 2)
                .sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
                .map((event, i) => (
                  <div className="max-w-2xl" key={i}>
                    <p className="text-md mb-2 text-secondary-200 font-bold md:text-lg">
                      {event.name}
                    </p>
                    <p className="mb-2 text-sm md:text-base">
                      {format(
                        new Date(event.startDatetime),
                        "MMMM dd, yyyy, h:mmaaa"
                      )}
                      {event.endDatetime && (
                        <>
                          <span> to </span>
                          {format(
                            new Date(event.endDatetime),
                            "MMMM dd, yyyy, h:mmaaa"
                          )}
                        </>
                      )}
                    </p>
                    {event.description && (
                      <div className="line-clamp-2 text-sm md:text-base">
                        {event.description}
                      </div>
                    )}
                    {event.url && (
                      <a
                        href={event.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="min-w-150 mt-4 px-4 py-2 hover:text-secondary-200 font-bold hover:bg-white border-2 border-secondary-100 rounded-full focus:outline-none transition">
                          REGISTER
                        </button>
                      </a>
                    )}
                  </div>
                ))}
            </div>
          </div>

          <div className="flex flex-col justify-center w-full text-white bg-program-sponsor-teaser bg-cover bg-center bg-no-repeat lg:px-16 lg:py-32 lg:w-1/2 lg:h-auto">
            <div className="p-16 space-y-4">
              <h2 className="text-2xl font-bold lg:text-4xl">
                Be a Program Sponsor
              </h2>
              <button
                onClick={() => setModalOpen(true)}
                className="border-2
						font-bold
						border-secondary-100
						py-2
						px-4
						rounded-full
						min-w-150
						transition
						focus:outline-none
						hover:bg-white
						hover:text-secondary-200"
              >
                LEARN MORE
              </button>
              <AnimatePresence exitBeforeEnter>
                {modalOpen && (
                  <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    key="Sponsorship Form"
                  >
                    <SponsorshipForm />
                  </Modal>
                )}
              </AnimatePresence>
            </div>
          </div>
        </section>

        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    site: sanityHomepage {
      text
      tagline
      image {
        asset {
          url
        }
      }
      leaders {
        name
        position
        image {
          asset {
            url
          }
        }
        company {
          name
          logo {
            asset {
              url
            }
          }
        }
        eventDate
      }
      webinar {
        _id
        name
        event {
          name
        }
      }
      about {
        _key
        _rawChildren
        _type
        children {
          _key
          _type
          marks
          text
        }
        list
        style
      }
      events {
        name
        startDatetime
        endDatetime
        url
        description
      }
      eventBanner {
        name
        url
        startDatetime
        endDatetime
      }
      eventBannerButton
    }
  }
`
