import React from "react"
import { navigate } from "gatsby"
import { FaBuilding, FaUser } from "react-icons/fa"
import { format } from "date-fns"


function LeaderEventCard({ name, position, image, eventDate, companyImage }) {
  return (
    <div className="event-card-height relative mx-auto w-64 bg-gray-600 border-b-8 border-gray-400 object-center">
      {image ? (
        <img
          className="relative mx-auto w-full h-56 object-cover object-center"
          src={image}
          alt={name}
        />
      ) : (
        <div className="relative grid place-items-center mx-auto w-full h-56 text-9xl bg-secondary-200">
          <FaUser />
        </div>
      )}

      {companyImage ? (
        <img
          className="relative z-10 -mt-4 mx-auto p-2 w-16 h-16 bg-white rounded-full object-contain object-center"
          src={companyImage}
          alt={name || "Company Logo"}
        />
      ) : (
        <div className="relative z-10 grid place-items-center -mt-4 mx-auto p-2 w-16 h-16 text-gray-600 text-2xl bg-white rounded-full">
          <FaBuilding />
        </div>
      )}

      <div className="-mt-4 p-6 space-y-2">
        <h3 className="text-lg font-bold">{name || "Thought Leader"}</h3>
        <p className="text-xs font-bold">{position || "Position"}</p>
        {eventDate && (
          <p className="text-xs italic">{format(
            new Date(eventDate),
            "MMMM dd, yyyy"
          )}</p>
        )}
        <button
          onClick={() => navigate(`/programs#${name}`)}
          className="max-w-300 absolute bottom-6 mt-4 px-4 py-2 text-white text-xs font-bold bg-secondary-200 hover:bg-opacity-75 rounded-full focus:outline-none transition lg:text-sm"
        >
          ABOUT
        </button>
      </div>
    </div>
  )
}

export default LeaderEventCard
